import { Autocomplete, TextField } from "@mui/material";
import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import usePaymentTypes from "./hooks/use-payment-types";

const PaymentMethods = ({
    name = "payment_method",
    label = "Payment Methods",
    multiple = true,
}) => {
    const {
        watch,
        setValue,
        formState: { errors },
    } = useFormContext();
    const types = usePaymentTypes();

    const paymentMethods = watch(name);

    const values = useMemo(() => {
        if (multiple) {
            return (
                types.filter(({ id }) => paymentMethods.indexOf(id) > -1) || []
            );
        }
        return types.find(({ id }) => id === paymentMethods) || null;
    }, [paymentMethods, types, multiple]);

    return (
        <Autocomplete
            limitTags={1}
            size='small'
            value={values}
            multiple={multiple}
            onChange={(_, v) => {
                setValue(name, multiple ? v?.map(({ id }) => id) : v?.id);
            }}
            options={types}
            getOptionLabel={(label) => label.name}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    helperText={
                        Boolean(errors[name]) ? errors[name].message : ""
                    }
                    error={Boolean(errors[name])}
                />
            )}
        />
    );
};

export default PaymentMethods;
