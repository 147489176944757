import { useEffect, useState } from "react";
import axiosInstance from "src/utils/axios";

/**
 * @name useGetTokenList
 * @param {Function} handleFetch
 * @default false
 * @returns {list: Array[], fetchTokens: function}
 */

const useGetTokenList = (open) => {
  const [list, setList] = useState([]);

  const fetchData = async () => {
    try {
      const { data } = await axiosInstance.get("api/admin/setup-token-list");
      setList(data.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (open) {
      fetchData();
    }
  }, [open]);

  return list;
};

export default useGetTokenList;
