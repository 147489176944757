import { Autocomplete, TextField } from "@mui/material";
import { useFormContext } from "react-hook-form";

const RHFAutoComplete = ({
  options,
  getOptionLabel,
  name,
  label,
  multiple,
  defaultValue,
  size,
  ...rest
}) => {
  const {
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  const selected = watch(name);
  const values = multiple
    ? options.filter(({ id }) => selected?.includes(id))
    : options.find(({ id }) => id === selected);

  return (
    <Autocomplete
      value={values}
      limitTags={1}
      defaultValue={defaultValue}
      multiple={multiple}
      onChange={(_, v) => {
        if (v) setValue(name, multiple ? v.map(({ id }) => id) : v.id);
        else setValue(name, null);
      }}
      size={size}
      options={options}
      getOptionLabel={getOptionLabel}
      renderInput={(params) => (
        <TextField
          label={label}
          {...params}
          error={Boolean(errors[name])}
          helperText={errors[name]?.message}
          size={size}
          name={name}
        />
      )}
      {...rest}
    />
  );
};

export default RHFAutoComplete;
