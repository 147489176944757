import { DialogActions } from "@material-ui/core";
import { LoadingButton } from "@mui/lab";
import { Button, DialogContent, Stack } from "@mui/material";
import React from "react";
import { useFormContext } from "react-hook-form";
import { RHFTextField } from "src/components/hook-form";

const DepositWalletTransfer = ({ handleClose }) => {
    const {
        formState: { isSubmitting },
    } = useFormContext();
    return (
        <>
            <DialogContent>
                <Stack mt={1} spacing={2}>
                    <RHFTextField
                        name='amount'
                        size='small'
                        label='Amount'
                        sx={{ mt: 2 }}
                    />

                    <RHFTextField
                        multiline
                        label='Notes'
                        name='notes'
                        rows={4}
                        fullWidth
                        sx={{ mt: 2 }}
                        maxRows={4}
                        minRows={4}
                        size='small'
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    loading={isSubmitting}
                    type='submit'
                    variant='contained'>
                    Submit
                </LoadingButton>
                <Button
                    onClick={handleClose}
                    variant='outlined'
                    color='warning'>
                    Cancel
                </Button>
            </DialogActions>
        </>
    );
};

export default DepositWalletTransfer;
