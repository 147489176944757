import { Autocomplete, TextField } from "@mui/material";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import useProductList from "src/components/ProductAutoComplete/hooks/useProductList";

const Product = ({
  name,
  label,
  size,
  isCombo = false,
  defaultValue,
  ...rest
}) => {
  const productList = useProductList(isCombo);
  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { ref, onChange, ...field } }) => (
        <Autocomplete
          clearOnBlur
          clearOnEscape
          defaultValue={defaultValue}
          onChange={(_, v) => {
            if (v) onChange(v.id);
            else onChange("");
          }}
          options={productList}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField
              label={label}
              {...field}
              {...params}
              inputRef={ref}
              error={Boolean(errors[name])}
              helperText={errors[name]?.message}
              size={size}
            />
          )}
          {...rest}
        />
      )}
    />
  );
};

export default Product;
