import PropTypes from "prop-types";
// @mui
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
//
import { ReactComponent as Nodata } from "src/images/no-data.svg";
import Image from "./Image";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  height: "100%",
  display: "flex",
  textAlign: "center",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(8, 2),
}));

// ----------------------------------------------------------------------

EmptyContent.propTypes = {
  title: PropTypes.string.isRequired,
  img: PropTypes.string,
  description: PropTypes.string,
};

export default function EmptyContent({ title, description, img, ...other }) {
  return (
    <>
      <RootStyle {...other}>
        <Nodata
          height={240}
          style={{
            marginBottom: 3,
            color: "#000",
          }}
        />
        <Typography variant="h5" gutterBottom>
          {title}
        </Typography>

        {description && (
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            {description}
          </Typography>
        )}
      </RootStyle>
    </>
  );
}
