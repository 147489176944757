import React, { useEffect, useState } from "react";
import axiosInstance from "src/utils/axios";

const useGetPaymentMethods = () => {
  const [paymentMethods, setPaymentMethods] = useState([]);
  const fetchPaymentMethods = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        "/api/admin/payment-types"
      );
      if (status) {
        setPaymentMethods(data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    fetchPaymentMethods();
  }, []);
  return paymentMethods;
};

export default useGetPaymentMethods;
