import buildPath from "src/utils/buildPath";

const URLS = {
    builder: "business-builder-report",
    credit: "fund-credit-report?type=credit",
    debit: "fund-credit-report?type=debit",
    fundCredit: "fund-credit-report",
    joining: "joining-report",
    income: "income-report",
    payout: "payout-report",
    point: "point-history-report",
    sales: "sales-report",
    earners: "top-earners-report",
    transaction: "transaction-report",
    user_subscription: "user-subscriptions-details",
    monthlySales: "dashboard/monthly-sales-report",
    fundTransactionHistory: "fund-transaction-history",
    statistics: (pid) => buildPath("product-statistics-users-list", pid),
    adminExpense: "admin-expense",
    ewallet: "ewallet",
    depositWallet: "deposit-wallet",
    businessBuilder: "business-builder-user-sales",
    holdingTank: "holding-tank",
    bbStatistics: "business-builder-subscription-sales",
    walletAddresses: "user-wallet-list",
    walletHistory: "admin-transaction-history",
    allSubscriptionUsers: (pid) =>
        buildPath("product-statistics-users-list", pid),
    financialReport: "financial-report-sales-table",
    gift: "gift-product-list",
    topPurchase: "top-purchased-users-list",
};

export const getUrl = (url) => URLS[url];
