import { LoadingButton } from "@mui/lab";
import { Box, useMediaQuery } from "@mui/material";
import { useFormContext } from "react-hook-form";
import Iconify from "./Iconify";

const GetReport = ({ size, fullWidth = false }) => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const buttonProps = {
    size: isSmallScreen ? "small" : "medium",
    fullWidth,
  };
  const {
    formState: { isSubmitting },
  } = useFormContext();

  return (
    <Box sx={{ justifyContent: "center" }}>
      <LoadingButton
        {...buttonProps}
        type="submit"
        variant="contained"
        size={size}
        loading={isSubmitting}
        endIcon={<Iconify icon="octicon:search-16" />}
      >
        Search
      </LoadingButton>
    </Box>
  );
};

export default GetReport;
