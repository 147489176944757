import { TextField } from "@mui/material";
import { useFormContext } from "react-hook-form";

const SelectRefundOption = () => {
  const {
    setValue,
    formState: { errors },
  } = useFormContext();
  const { watch } = useFormContext();

  const value = watch("status");
  return (
    <TextField
      select
      fullWidth
      size={"small"}
      value={value}
      SelectProps={{ native: true }}
      label="Refund Status"
      error={Boolean(errors.status)}
      helperText={errors.status?.message}
      name="status"
      onChange={(e) => {
        const { value } = e.target;
        setValue("status", value === "all" ? null : value);
      }}
    >
      <option value="all">All</option>
      <option value="refund">Refund</option>
      <option value="sale">Sale</option>
    </TextField>
  );
};

export default SelectRefundOption;
