import { Box, Button } from "@mui/material";
import { capitalCase, snakeCase } from "change-case";
import { useForm } from "react-hook-form";
import {
  FormProvider,
  RHFSelect,
  RHFTextField,
} from "src/components/hook-form";
import RHFDatePicker from "src/components/hook-form/RHFDatePicker";
import useLocales from "src/hooks/useLocales";
import Users from "src/components/users";
import RHFAutoComplete from "src/components/hook-form/RHFAutoComplete";
import { countries } from "src/_mock";
import useGetPaymentType from "../hooks/useGetPaymentType";
import useProductList from "src/components/ProductAutoComplete/hooks/useProductList";
import useGetPaymentMethods from "src/pages/financial/financialReport/components/hook/useGetPaymentMethods";
import { useEffect } from "react";
import Product from "./Product";
import UsersSearch from "src/components/autoComplete/users";
var date = new Date();
const defaultValues = {
  start_date: new Date(
    date.getFullYear(),
    date.getMonth(),
    1
  ).toLocaleDateString("en-GB"),
  end_date: new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    0
  ).toLocaleDateString("en-GB"),
  user_id: null,
  email: null,
  payment_type: null,
  purchase_type: null,
  status: null,
  payment_status: null,
  product_id: null,
};

const Filter = ({ heading, setFilter }) => {
  const paymentMethod = useGetPaymentMethods();
  const paymentType = useGetPaymentType();
  const productList = useProductList();
  const checkHeading = snakeCase(heading);
  const methods = useForm({ defaultValues });
  const { translate } = useLocales();
  const onSubmit = (data) => {
    setFilter(data);
  };
  useEffect(() => {
    if (checkHeading) {
      setFilter({
        start_date: new Date(
          date.getFullYear(),
          date.getMonth(),
          1
        ).toLocaleDateString("en-GB"),
        end_date: new Date(
          date.getFullYear(),
          date.getMonth() + 1,
          0
        ).toLocaleDateString("en-GB"),
        user_id: null,
        email: null,
        payment_type: null,
        purchase_type: null,
        status: null,
        payment_status: null,
        product_id: null,
      });
      methods.reset({...defaultValues});
    }
  }, [checkHeading, methods]);
  return (
    <FormProvider methods={methods} onSubmit={methods.handleSubmit(onSubmit)}>
      {(checkHeading === "business_builder_report" ||
        checkHeading === "fund_credit_and_debit_report") && (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: {
              xs: "repeat(1,1fr)",
              sm: "repeat(5, 1fr)",
            },
            rowGap: 3,
            columnGap: 3,
            marginBottom: "2rem",
          }}
        >
          <RHFDatePicker
            label={translate("adminFinancial.payout.pickStartDate")}
            name="start_date"
            size="small"
          />
          <RHFDatePicker
            label={translate("adminFinancial.payout.pickEndDate")}
            name="end_date"
            size="small"
          />

       
          <UsersSearch name="user_id" props={{ size: "small" }} />
          <RHFTextField name="email" label="Email" size="small" />

          <Button type="submit" variant="contained" size="medium">
            Get Report
          </Button>
        </Box>
      )}
      {checkHeading === "joining_report" && (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: {
              xs: "repeat(1,1fr)",
              sm: "repeat(5 , 1fr)",
            },
            rowGap: 3,
            columnGap: 3,
            marginBottom: "2rem",
          }}
        >
          <RHFDatePicker
            label={translate("adminFinancial.payout.pickStartDate")}
            name="start_date"
            size="small"
          />
          <RHFDatePicker
            label={translate("adminFinancial.payout.pickEndDate")}
            name="end_date"
            size="small"
          />

          <UsersSearch name="user_id" props={{ size: "small" }} />

          <RHFSelect name="country" label="Choose country" size="small">
            <option/>
            {countries.map(({ code, label }) => {
              return <option value={code}>{label}</option>;
            })}
          </RHFSelect>
          <RHFTextField name="email" label="Email" size="small" />
          <Button type="submit" variant="contained" size="medium">
            Get Report
          </Button>
        </Box>
      )}
      {checkHeading === "member_income_report" && (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: {
              xs: "repeat(1,1fr)",
              sm: "repeat(5, 1fr)",
            },
            rowGap: 3,
            columnGap: 3,
            marginBottom: "2rem",
          }}
        >
          <RHFDatePicker
            label={translate("adminFinancial.payout.pickStartDate")}
            name="start_date"
            size="small"
          />
          <RHFDatePicker
            label={translate("adminFinancial.payout.pickEndDate")}
            name="end_date"
            size="small"
          />

          <UsersSearch name="user_id" props={{ size: "small" }} />

          <RHFSelect name="bonus_type" label="Bonus Type" size="small">
            <option>All</option>
            {paymentType.map(({ payment_type }) => {
              return (
                <option value={payment_type}>
                  {capitalCase(payment_type)}
                </option>
              );
            })}
          </RHFSelect>

          <Button type="submit" variant="contained" size="medium">
            Get Report
          </Button>
        </Box>
      )}
      {checkHeading === "payout_report" && (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: {
              xs: "repeat(1,1fr)",
              sm: "repeat(5, 1fr)",
            },
            rowGap: 3,
            columnGap: 3,
            marginBottom: "2rem",
          }}
        >
          <RHFDatePicker
            label={translate("adminFinancial.payout.pickStartDate")}
            name="start_date"
            size="small"
          />
          <RHFDatePicker
            label={translate("adminFinancial.payout.pickEndDate")}
            name="end_date"
            size="small"
          />

          <UsersSearch name="user_id" props={{ size: "small" }} />

          <RHFSelect name="status" label="Status" size="small">
            <option value="" />
            <option value="failed">Failed</option>
            <option value="approved">Approved</option>
            <option value="pending">Pending</option>
            <option value="rejected">Rejected</option>
          </RHFSelect>

          <Button type="submit" variant="contained" size="medium">
            Get Report
          </Button>
        </Box>
      )}
      {checkHeading === "point_history_report" && (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: {
              xs: "repeat(1,1fr)",
              sm: "repeat(5, 1fr)",
            },
            rowGap: 3,
            columnGap: 3,
            marginBottom: "2rem",
          }}
        >
          <RHFDatePicker
            label={translate("adminFinancial.payout.pickStartDate")}
            name="start_date"
            size="small"
          />
          <RHFDatePicker
            label={translate("adminFinancial.payout.pickEndDate")}
            name="end_date"
            size="small"
          />

          <UsersSearch name="user_id" props={{ size: "small" }} />
          <RHFTextField name="email" label="Email" size="small" />
          <RHFSelect name="leg" label="Leg" size="small">
            <option />
            <option value="L">left</option>
            <option value="R">right</option>
          </RHFSelect>
          <RHFSelect name="commision_type" label="Commission Type" size="small">
            <option />
            <option value="onlinestore">Onlinestore</option>
            <option value="business_fee">Business Fee</option>
          </RHFSelect>

          <Button type="submit" variant="contained" size="medium">
            Get Report
          </Button>
        </Box>
      )}
      {checkHeading === "sales_report" && (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: {
              xs: "repeat(1,1fr)",
              sm: "repeat(5, 1fr)",
            },
            rowGap: 3,
            columnGap: 3,
            marginBottom: "2rem",
          }}
        >
          <RHFDatePicker
            label={translate("adminFinancial.payout.pickStartDate")}
            name="start_date"
            size="small"
          />
          <RHFDatePicker
            label={translate("adminFinancial.payout.pickEndDate")}
            name="end_date"
            size="small"
          />

          <UsersSearch name="user_id" props={{ size: "small" }} />
          <RHFTextField name="email" label="Email" size="small" />
       
          <Product name="product_id" label="Select Product" size="small" />
          <Button type="submit" variant="contained" size="medium">
            Get Report
          </Button>
        </Box>
      )}
      {checkHeading === "top_earners_report" && (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: {
              xs: "repeat(1,1fr)",
              sm: "repeat(5, 1fr)",
            },
            rowGap: 3,
            columnGap: 3,
            marginBottom: "2rem",
          }}
        >
          <RHFDatePicker
            label={translate("adminFinancial.payout.pickStartDate")}
            name="start_date"
            size="small"
          />
          <RHFDatePicker
            label={translate("adminFinancial.payout.pickEndDate")}
            name="end_date"
            size="small"
          />

          <UsersSearch name="user_id" props={{ size: "small" }} />

          <Button type="submit" variant="contained" size="medium">
            Get Report
          </Button>
        </Box>
      )}
      {checkHeading === "payout_released_report" && (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: {
              xs: "repeat(1,1fr)",
              sm: "repeat(5, 1fr)",
            },
            rowGap: 3,
            columnGap: 3,
            marginBottom: "2rem",
          }}
        >
          <RHFDatePicker
            label={translate("adminFinancial.payout.pickStartDate")}
            name="start_date"
            size="small"
          />
          <RHFDatePicker
            label={translate("adminFinancial.payout.pickEndDate")}
            name="end_date"
            size="small"
          />

          <UsersSearch name="user_id" props={{ size: "small" }} />
          <RHFSelect name="payment_method" label="Payment Method" size="small">
            <option />
            {paymentMethod.map(({ name, id }) => {
              return <option value={id}>{name}</option>;
            })}
          </RHFSelect>
          <RHFSelect name="purchase_type" label="Payment Type" size="small">
            <option />
            {paymentType.map(({ payment_type }) => {
              return (
                <option value={payment_type}>
                  {capitalCase(payment_type)}
                </option>
              );
            })}
          </RHFSelect>
          <RHFSelect name="payment_status" label="Payment Status" size="small">
            <option />
            <option value="fininshed">Fininshed</option>
            <option value="pending"> Pending</option>
            <option value="completed"> Completed</option>
          </RHFSelect>
          <RHFTextField name="email" label="Email" size="small" />
          <Button type="submit" variant="contained" size="medium">
            Get Report
          </Button>
        </Box>
      )}
    </FormProvider>
  );
};

export default Filter;
