import { DialogContent } from "@material-ui/core";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import axiosInstance from "src/utils/axios";
import useLocales from "src/hooks/useLocales";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";

const BlockDialog = ({ open, selectedId, onClose, fetchData, isBlocked }) => {
  const { translate } = useLocales();
  const theme = useTheme();
  const { palette } = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const handleBlock = async () => {
    setIsLoading(true);
    const { status, data: responseData } = await axiosInstance.get(
      `/api/admin/block-user/${selectedId}`
    );

    if (status === 200) {
      setIsLoading(false);
      enqueueSnackbar(responseData.message);
      onClose();
      fetchData();
      return;
    }
    enqueueSnackbar("Failed to block member", { variant: "error" });
  };

  return (
    <Dialog
      
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={onClose}
      aria-labelledby="block-user"
    >
      <DialogTitle id="block-user">
        {isBlocked
          ? "Unblock User"
          : translate("adminMembersManagement.networkMembers.blockUser")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Box
            sx={{
              display: "grid",
              rowGap: 3,
              columnGap: 2,
              marginTop: 3,
              gridTemplateColumns: {
                xs: "repeat(1, 1fr)",
                sm: "repeat(1, 1fr)",
              },
            }}
          >
            <Typography>
              {translate("adminMembersManagement.networkMembers.areYouSure")}
            </Typography>
          </Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} sx={{ color: palette.warning.normal }}>
          {translate("adminMembersManagement.networkMembers.cancel")}
        </Button>

        <LoadingButton
          onClick={handleBlock}
          variant="contained"
          color="warning"
          loading={isLoading}
        >
          {isBlocked
            ? "Unblock"
            : translate("adminMembersManagement.networkMembers.block")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default BlockDialog;
