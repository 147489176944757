import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Stack,
    Tab,
    Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FormProvider, RHFTextField } from "src/components/hook-form";
import axiosInstance from "src/utils/axios";
import { number, object, string } from "yup";
import DepositWalletTransfer from "./deposit-wallet-transfer";
import TokenTransfer from "./token-transfer";
import Ternary from "src/components/ternary";

const defaultValues = {
    amount: "",
    wallet_type: "deposit_wallet",
    user_id: "",
    notes: "",
};
const schema = object().shape({
    amount: number()
        .transform((val, orig) => (orig == "" ? undefined : val))
        .required("amount is required")
        .typeError("amount must be a number"),
});
const FundTransfer = ({ open, handleClose, selectedId, walletData }) => {
    const { enqueueSnackbar } = useSnackbar();
    const methods = useForm({ defaultValues, resolver: yupResolver(schema) });
    const { reset, setError, setValue } = methods;
    useEffect(() => {
        setValue("user_id", selectedId);
    }, [selectedId]);
    const onSubmit = async (inputData) => {
        const reqData = new FormData();
        Object.entries(inputData).forEach(([key, value]) =>
            reqData.append(key, value)
        );
        try {
            const { status, data } = await axiosInstance.post(
                "api/admin/credit-fund",
                reqData
            );
            if (status === 200) {
                enqueueSnackbar(data.message);
                reset();
                handleClose();
            }
        } catch (err) {
            enqueueSnackbar(err.message, { variant: "error" });
            if (Boolean(err.errors)) {
                Object.entries(err.errors).forEach(([key, value]) => {
                    setError(key, { message: value[0] });
                });
            }
        }
    };
    const [tab, setTab] = useState("1");

    const handleChange = (event, newValue) => {
        setTab(newValue);
    };
    return (
        <Dialog onClose={handleClose} open={open} fullWidth>
            <DialogTitle>Fund Transfer</DialogTitle>
            <Box sx={{ width: "100%", typography: "body1" }}>
                <TabContext value={tab}>
                    <Box
                        sx={{ borderBottom: 1, borderColor: "divider", px: 3 }}>
                        <TabList
                            onChange={handleChange}
                            aria-label='lab API tabs example'>
                            <Tab label='Deposit Wallet' value='1' />
                            <Tab label='Whisper Drop' value='2' />
                        </TabList>
                    </Box>
                    <TabPanel value='1'>
                        {" "}
                        <FormProvider
                            methods={methods}
                            onSubmit={methods.handleSubmit(onSubmit)}>
                            <DepositWalletTransfer handleClose={handleClose} />
                        </FormProvider>
                    </TabPanel>
                    <TabPanel value='2'>
                        <TokenTransfer
                            userId={selectedId}
                            walletData={walletData}
                            handleClose={handleClose}
                        />
                    </TabPanel>
                </TabContext>
            </Box>
        </Dialog>
    );
};

export default FundTransfer;
