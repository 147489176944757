import React, { useEffect, useState } from "react";
import axiosInstance from "src/utils/axios";

const useGetPaymentType = () => {
  const [paymentType, setPaymentType] = useState([]);
  const fetchPayment = async () => {
    try {
      const { status, data } = await axiosInstance(
        "/api/admin/income-report-payment-type"
      );
      if (status) {
        setPaymentType(data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    fetchPayment();
  }, []);
  return paymentType;
};

export default useGetPaymentType;
