import { IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import Iconify from "src/components/Iconify";

const CopyButton = ({ value }) => {
  const [copied, setCopied] = useState(false);
  const { palette } = useTheme();

  const onCopy = (e) => {
    navigator.clipboard.writeText(value);
    setCopied(true);
  };

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, [1000]);
    }
  }, [copied]);

  return (
    <IconButton size="small" onClick={onCopy}>
      <Iconify
        icon={copied ? "ic:round-check" : "akar-icons:copy"}
        sx={{
          color: copied ? palette.success.main : "default",
        }}
      />
    </IconButton>
  );
};

export default CopyButton;
