import detectEthereumProvider from "@metamask/detect-provider";
import { ethers } from "ethers";

const getSigner = async (chain) => {
  // For more information about using metamask with web3, see: https://docs.metamask.io/guide/
  const metamaskProvider = await detectEthereumProvider();
  await metamaskProvider.request({ method: "eth_requestAccounts" });
  // Check the chain ID to ensure we are on the correct network
  const chainId = await metamaskProvider.request({ method: "eth_chainId" });
  // If we are not on bsc testnet, switch to it
  // This is a good resource for chain IDs: https://chainlist.org/
  //for production 0x38
  if (chainId !== chain.chainId) {
    try {
      await metamaskProvider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: chain.chainId }],
      });
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (switchError.code === 4902) {
        try {
          // Add chain to metamask
          await metamaskProvider.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: chain.chainId,
                chainName: chain.chainName,
                nativeCurrency: {
                  name: chain.nativeCurrency.name,
                  symbol: chain.nativeCurrency.symbol,
                  decimals: chain.nativeCurrency.decimals,
                },
                rpcUrls: chain.rpcUrls,
                blockExplorerUrls: chain.blockExplorerUrls,
              },
            ],
          });
        } catch (addError) {
          // handle "add" error
          console.log("addError", addError);
        }
      }
      // handle other "switch" errors
    }
  }

  // Instantiate the ethers provider and signer
  const ethersProvider = new ethers.providers.Web3Provider(metamaskProvider);

  return ethersProvider.getSigner();
};

export default getSigner;
