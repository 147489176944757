import {
  Box,
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import useLocales from "src/hooks/useLocales";
import Iconify from "../Iconify";
import EmptyTable from "../emptyTable";
import Loop from "../loop";
import Ternary from "../ternary";

const DataHandlerTable = ({
  dataProps,
  headers = [],
  children,
  tableProps = {},
}) => {
  const { translate } = useLocales();
  const { loading, error, isArrayEmpty } = dataProps;
  return (
    <TableContainer component={Paper}>
      <Table {...tableProps}>
        <TableHead>
          <TableRow>
            <Loop
              list={headers}
              render={(item) => {
                if (typeof item === "string")
                  return <TableCell>{translate(item)}</TableCell>;
                if (item.type === "component") {
                  return (
                    <TableCell>
                      {item.component} {translate(item.label)}
                    </TableCell>
                  );
                }
                return (
                  <TableCell>
                    {translate(item.label)}&nbsp;
                    <IconButton size="small" onClick={item.onClick}>
                      <Iconify icon={item.icon} />
                    </IconButton>
                  </TableCell>
                );
              }}
            />
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
      </Table>
      <Ternary
        when={loading}
        then={
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginTop: 3,
              marginBottom: 3,
            }}
          >
            <CircularProgress />
          </Box>
        }
        otherwise={
          <Ternary when={isArrayEmpty} then={<EmptyTable error={error} />} />
        }
      />
    </TableContainer>
  );
};

export default DataHandlerTable;
