import { useTheme } from "@mui/material/styles";
import {
    Box,
    IconButton,
    MenuItem,
    Popover,
    Stack,
    TableCell,
    Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useOutletContext } from "react-router-dom";
import DataHandlerTable from "src/components/data-handler/table";
import ParseDate, { CreatedDate } from "src/components/date";
import FilterBar from "src/components/filterBar";
import Iconify from "src/components/Iconify";
import Loop from "src/components/loop";
import PaginationButtons from "src/components/pagination";
import TableMenu from "src/components/tableMenu";
import Ternary from "src/components/ternary";
import { Dollar } from "src/components/with-prefix";
import useQueryParams from "src/hooks/useQueryParams";
import Actions from "src/pages/members/network/component/actions";
import BlockDialog from "src/pages/members/network/component/BlockDialog.jsx";
import EditUserNameDialog from "src/pages/members/network/component/editUserNameDialog.jsx";
import TurnOfMail from "src/pages/members/network/component/turnOfMailDialog";
import UpdatePasswordDialog from "src/pages/members/network/component/updatePasswordDialog.jsx";
import VerifyMail from "src/pages/members/network/component/verifyMail.jsx";
import { PATH_DASHBOARD } from "src/routes/paths";
import { BodyRow } from "../components";
import useReport from "../hooks/useReport";
import RefundDialog from "./components/refund-dialog";
import SalesFilter from "./SalesFilter";
import FundTransfer from "./components/fund-transfer";
import { useForm } from "react-hook-form";
const defaultValues = {
    start_date: "",
    end_date: "",
    user_id: null,
    email: null,
    payment_type: null,
    purchase_type: null,
    status: null,
    payment_status: [],
    product_id: null,
    bonus_type: null,
    leg: null,
    commision_type: null,
    payment_method: [],
    refund_payment_method_id: [],
};
const headers = ["No", "Username", "Email", "Amount", "Date", "Action"];

const TopPurchasedUsers = ({ title, heading, type }) => {
    const { methods: filterMethods } = useOutletContext();
    const methods = useForm({ defaultValues });
    const { palette } = useTheme();
    const filter = methods.watch();

    const {
        state,
        getReport,
        handleGetFilter,
        rowStart,
        totalSummary,
        ...rest
    } = useReport("topPurchase", filter, {
        title,
        heading,
        type,
    });

    useEffect(() => {
        filterMethods.reset(filter);
    }, [filter.start_date, filter.end_date]);
    const { data: report, ...dataProps } = state;
    console.log(report);
    const [openMenu, setOpenMenuActions] = useState(null);
    const [selectedId, setSelectedId] = useState(null);
    const [isBlocked, setIsBlocked] = useState(false);
    const [isMailTurnedOn, setIsMailTurnedOn] = useState(false);
    const [isMailVerified, setIsMailVerified] = useState(false);
    const [walletData, setWalletData] = useState(null);

    const [refundData, setRefundData] = useState({
        open: false,
        status: "",
        invoice_id: "",
    });

    const handleOpenRefund = () => {
        setRefundData((state) => ({ ...state, open: true }));
    };

    const handleCloseRefund = () => {
        setRefundData({ status: "", invoice_id: "", open: false });
        handleCloseMenu();
    };

    const handleOpenMenu =
        (
            status,
            invoiceId,
            id,
            blockedStatus,
            isMailTurnedOn,
            mailVerified,
            walletData
        ) =>
        (event) => {
            setRefundData({ open: false, status, invoice_id: invoiceId });
            setIsBlocked(blockedStatus);
            setOpenMenuActions(event.currentTarget);
            console.log(id);
            setSelectedId(id);
            setIsMailTurnedOn(isMailTurnedOn);
            setIsMailVerified(mailVerified);
            setWalletData(walletData);
        };

    const handleCloseMenu = () => {
        setOpenMenuActions(null);
    };

    const [openChangePassword, setOpenChangePassword] = useState(false);

    const handleClickOpenChangePassword = () => {
        setUserName(getUserName());
        setOpenChangePassword(true);
        handleCloseMenu();
    };

    const handleCloseChangePassword = () => {
        setOpenChangePassword(false);
        setUserName(null);
    };

    const [username, setUserName] = useState(null);
    const getUserName = () =>
        report.find((member) => member.user_id === selectedId).user.username;

    const [openChangeUsername, setOpenChangeUsername] = useState(false);

    const handleClickOpenChangeUsername = () => {
        setUserName(getUserName());
        setOpenChangeUsername(true);
        handleCloseMenu();
    };

    const handleCloseChangeUsername = () => {
        setOpenChangeUsername(false);
        setUserName(null);
    };

    const [openBlock, setOpenBlock] = useState(false);
    const handleOpenBlock = () => setOpenBlock(true);
    const handleCloseBlock = () => {
        setOpenBlock(false);
        handleCloseMenu();
    };

    const [openTurnOnMail, setOpenTurnOnMail] = useState(false);

    const handleOpenTurnOnMail = () => {
        setOpenTurnOnMail(true);
        handleCloseMenu();
    };

    const handleCloseTurnOnMail = () => {
        setOpenTurnOnMail(false);
    };

    const [openVerify, setOpenVerify] = useState(false);
    const handleOpenVerify = () => {
        setOpenVerify(true);
        handleCloseMenu();
    };

    const handleCloseVerify = () => {
        setOpenVerify(false);
    };

    const [openFundTransfer, setOpenFundTransfer] = useState(false);
    const handleOpenFundTransfer = () => {
        setOpenFundTransfer(true);
        handleCloseMenu();
    };
    const handleCloseFundTransfer = () => {
        setOpenFundTransfer(false);
        handleCloseMenu();
        setSelectedId(null);
    };

    const { deleteParam } = useQueryParams();
    const onFilter = methods.handleSubmit(async (inputData) => {
        await getReport(1, inputData);
        deleteParam("product_id");
    });
    const [paymentMethodPopover, setPaymentMethodPopover] = useState({
        data: null,
        anchorEl: null,
    });
    const { anchorEl, data: refundRemark } = paymentMethodPopover;
    const handleClose = () =>
        setPaymentMethodPopover({
            data: null,
            anchorEl: null,
        });
    return (
        <>
            <FilterBar>
                <SalesFilter
                    methods={methods}
                    onFilter={onFilter}
                    total={totalSummary}
                />
            </FilterBar>

            <DataHandlerTable headers={headers} dataProps={dataProps}>
                <Loop
                    list={report}
                    render={(row, i) => (
                        <BodyRow key={row.id}>
                            <TableCell component='th' scope='row'>
                                {i + rowStart}
                            </TableCell>

                            <TableCell align='left'>
                                {row.user?.username}
                            </TableCell>

                            <TableCell align='left'>
                                {row.user?.email}
                            </TableCell>

                            <TableCell align='left'>
                                <Dollar>{row.amount}</Dollar>
                            </TableCell>

                            <TableCell>
                                <ParseDate date={row?.created_at} />
                            </TableCell>
                            <TableCell align='left'>
                                <IconButton
                                    onClick={handleOpenMenu(
                                        row.status,
                                        row.id,
                                        row.user_id,
                                        !row?.user.active,
                                        Boolean(row?.user.is_turn_on_email),
                                        Boolean(row?.user.email_verified_at),
                                        row.user.user_meta_mask?.length > 0
                                            ? row.user.user_meta_mask[0]
                                            : {}
                                    )}>
                                    <Iconify
                                        icon={"eva:more-vertical-fill"}
                                        width={20}
                                        height={20}
                                    />
                                </IconButton>
                            </TableCell>
                        </BodyRow>
                    )}
                />
            </DataHandlerTable>
            <PaginationButtons {...rest} />
            <TableMenu onClose={handleCloseMenu} open={openMenu}>
                <MenuItem
                    sx={{ color: "default.main" }}
                    onClick={handleOpenFundTransfer}>
                    <Iconify icon={"icon-park-solid:bank-transfer"} />
                    Fund Transfer
                </MenuItem>

                <Actions
                    handleOpenRefund={handleOpenRefund}
                    showRefund={refundData.status === "sale"}
                    isMailVerified={isMailVerified}
                    handleOpenVerify={handleOpenVerify}
                    handleOpenTurnOnMail={handleOpenTurnOnMail}
                    isMailTurnedOn={isMailTurnedOn}
                    isBlocked={isBlocked}
                    openChangePassword={handleClickOpenChangePassword}
                    openChangeUsername={handleClickOpenChangeUsername}
                    openBlock={handleOpenBlock}
                    selectedId={selectedId}
                    fetchMemberList={handleGetFilter}
                />
            </TableMenu>
            <FundTransfer
                open={openFundTransfer}
                handleClose={handleCloseFundTransfer}
                selectedId={selectedId}
                walletData={walletData}
            />
            <UpdatePasswordDialog
                open={openChangePassword}
                username={username}
                fetchData={handleGetFilter}
                onClose={handleCloseChangePassword}
            />
            <BlockDialog
                isBlocked={isBlocked}
                onClose={handleCloseBlock}
                open={openBlock}
                selectedId={selectedId}
                fetchData={handleGetFilter}
            />
            <TurnOfMail
                isMailTurnedOn={isMailTurnedOn}
                onClose={handleCloseTurnOnMail}
                open={openTurnOnMail}
                selectedId={selectedId}
                fetchData={handleGetFilter}
            />
            <VerifyMail
                fetchData={handleGetFilter}
                onClose={handleCloseVerify}
                open={openVerify}
                selectedId={selectedId}
            />

            <EditUserNameDialog
                open={openChangeUsername}
                onClose={handleCloseChangeUsername}
                username={username}
                fetchData={handleGetFilter}
            />

            <RefundDialog
                refundData={refundData}
                onClose={handleCloseRefund}
                reload={() => getReport(rest.page, filter)}
            />
            <Ternary
                when={Boolean(anchorEl)}
                then={
                    <Popover
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                        }}>
                        <Stack
                            spacing={1}
                            sx={{
                                padding: 2,
                                maxWidth: "200px",
                                maxHeight: "300px",
                                overflowY: "auto",
                            }}>
                            <Typography variant='subtitle2'>Note</Typography>
                            <Typography variant='body2'>
                                {refundRemark}
                            </Typography>
                        </Stack>
                    </Popover>
                }
            />
        </>
    );
};

export default TopPurchasedUsers;
