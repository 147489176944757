import { useEffect, useState } from "react";
import useErrors from "src/hooks/useErrors";
import axiosInstance from "src/utils/axios";

const useProductList = (isCombo = false) => {
  const handleErrors = useErrors();
  const [productList, setProductList] = useState([]);
  const fetchData = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `/api/admin/product-list`,
        {
          params: {
            is_combo: isCombo === null ? isCombo : Number(Boolean(isCombo)),
          },
        }
      );
      if (status === 200) {
        setProductList(data.data);
      }
    } catch (err) {
      handleErrors(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return productList;
};
export default useProductList;
