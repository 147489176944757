import { useSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import { useOutletContext } from "react-router";
import fetchReport from "src/api/admin/report/fetch-report";
import useDataHandler from "src/components/data-handler/hooks/use-data-handler";
import usePagination from "src/components/pagination/usePagination";
import useTimeOut from "src/components/timeout/hooks/useTimeOut";
import useQueryParams from "src/hooks/useQueryParams";
import { getUrl } from "../config";

const useReport = (uriKey, filter, { title, heading, type }) => {
    const { setData } = useOutletContext();
    const [state, actions] = useDataHandler();
    const { queryObject } = useQueryParams();
    const { start_date, end_date, product_id, status } = queryObject;

    const [totalSummary, setTotalSummary] = useState(0);

    const [report, setReport] = useState([]);
    const [timeout, setTimeOut] = useTimeOut(report?.length);
    const { count, onChange, page, seed, rowStart } = usePagination();
    const { enqueueSnackbar } = useSnackbar();

    const handleGetFilter = () => getReport(page, filter);

    const firstLoad = useRef(true);
    const getReport = async (page = 1, filter = {}) => {
        actions.loading();
        setReport([]);
        setTimeOut(false);
        const newFilter = Object.fromEntries(
            Object.entries(filter)
                .map(([k, v]) => (Boolean(v) ? [k, v] : null))
                .filter(Boolean)
        );

        const URI = `api/admin/${getUrl(uriKey)}`;
        try {
            const { data, status } = await fetchReport(URI, {
                ...newFilter,
                page,
                pagination: 1,
            });
            if (status === 200) {
                const { salesReportSum, businessBuilderReportSum, sum, total } =
                    data;
                const { last_page, from, data: list } = data.data;
                seed(last_page, from);
                onChange(null, page);
                actions.success(list);
                setReport(list);
                const parsedSummary = parseFloat(businessBuilderReportSum);

                if (parsedSummary >= 0) {
                    setTotalSummary(parsedSummary);
                }

                if (sum) {
                    setTotalSummary(sum);
                }

                if (total) {
                    setTotalSummary(total);
                }

                if (salesReportSum > -1) {
                    setTotalSummary(salesReportSum);
                }
                firstLoad.current = false;
            }
        } catch (err) {
            actions.error();
            Object.values(err?.errors).flatMap((item) =>
                enqueueSnackbar(item, { variant: "error" })
            );
        }
    };

    useEffect(() => {
        setData({ title, heading, type });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [title, heading, type]);

    useEffect(() => {
        if (firstLoad.current) {
            if (start_date && end_date) {
                getReport(1, {
                    ...filter,
                    start_date,
                    end_date,
                    product_id,
                    status,
                });
            } else {
                getReport(1, { ...filter, product_id, status });
            }
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uriKey, start_date, end_date, product_id, status]);

    useEffect(() => {
        if (firstLoad.current) {
            return;
        }

        getReport(page, filter);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    return {
        state,
        timeout,
        report,
        getReport,
        count,
        onChange,
        page,
        rowStart,
        handleGetFilter,
        totalSummary,
    };
};

export default useReport;
