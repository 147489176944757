import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Stack,
    TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import refundUser, { refundBusinessBuilder } from "src/api/admin/refund";
import { FormProvider, RHFTextField } from "src/components/hook-form";
import useQueryParams from "src/hooks/useQueryParams";
import { date, object, string } from "yup";
import PaymentMethods from "../../transaction/components/filter/components/payment-methods";
import RHFDatePicker from "src/components/hook-form/RHFDatePicker";
import moment from "moment";

const defaultValues = {
    password: "",
    remark: "",
    refund_payment_method_id: "",
    refund_date: "",
};

const schema = object().shape({
    password: string().required("Password is required").min(6),
    remark: string().required("Remark is required"),
    refund_payment_method_id: string().required("Payment method is required"),
    refund_date: string()
        .test(
            "is-valid",
            'Refund date is invalid, please input in the format "DD/MM/YYYY"',
            (v) => {
                if (v) {
                    const checkDate = moment(v, "DD/MM/YYYY");
                    return checkDate.isValid();
                }
                return true;
            }
        )
        .required("Refund date is required")
        .typeError("Refund date is required"),
});
const RefundDialog = ({ onClose, reload, businessBuilder, refundData }) => {
    const methods = useForm({ defaultValues, resolver: yupResolver(schema) });
    const {
        formState: { isSubmitting, errors },
    } = methods;
    const { enqueueSnackbar } = useSnackbar();
    const { open, invoice_id } = refundData;

    const handleClose = () => {
        onClose();
        methods.reset();
    };

    const onSubmit = async (inputData) => {
        const caller = businessBuilder ? refundBusinessBuilder : refundUser;
        try {
            const { status, message } = await caller(invoice_id, inputData);
            if (status) {
                enqueueSnackbar(message);
                onClose();
                handleClose();
                reload();
                methods.reset();
            }
        } catch (err) {
            enqueueSnackbar(err.message, { variant: "error" });
        }
    };

    return (
        <Dialog onClose={handleClose} open={open} maxWidth='xs' fullWidth>
            <DialogTitle>Confirm Refund</DialogTitle>
            <FormProvider
                methods={methods}
                onSubmit={methods.handleSubmit(onSubmit)}>
                <DialogContent>
                    <DialogContentText>
                        This action is irreversible are you sure you want to
                        continue?
                    </DialogContentText>
                    <Stack mt={1} spacing={2}>
                        <RHFTextField
                            name='password'
                            size='small'
                            label='Password'
                            type='password'
                            sx={{ mt: 2 }}
                        />
                        <PaymentMethods
                            name='refund_payment_method_id'
                            multiple={false}
                        />
                        <RHFDatePicker
                            name='refund_date'
                            size='small'
                            label='Refund Date'
                            sx={{ mt: 2 }}
                        />
                        <RHFTextField
                            multiline
                            label='Remark'
                            name='remark'
                            rows={4}
                            fullWidth
                            sx={{ mt: 2 }}
                            maxRows={4}
                            minRows={4}
                            size='small'
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        loading={isSubmitting}
                        // onClick={onSubmit}
                        type='submit'
                        variant='contained'
                        color='error'>
                        Confirm
                    </LoadingButton>
                    <Button
                        onClick={handleClose}
                        variant='outlined'
                        color='warning'>
                        Cancel
                    </Button>
                </DialogActions>
            </FormProvider>
        </Dialog>
    );
};

export default RefundDialog;
