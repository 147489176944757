import moment from "moment";
import axiosInstance from "src/utils/axios";
import buildPath from "src/utils/buildPath";

const genReqData = (inputData = {}, isRefund = false) => {
    const reqData = new FormData();

    if (isRefund) {
        const { refund_date, ...rest } = inputData;

        reqData.append(
            "refund_date",
            moment(refund_date, "DD/MM/YYYY").format("YYYY-MM-DD")
        );
        Object.entries({ ...rest, _method: "PUT" }).forEach(([k, v]) =>
            reqData.append(k, v)
        );
        return reqData;
    }
    Object.entries({ ...inputData, _method: "PUT" }).forEach(([k, v]) =>
        reqData.append(k, v)
    );
    return reqData;
};

const refundUser = async (invoiceId, inputData) => {
    const { data } = await axiosInstance.post(
        buildPath("/api/admin/refund-invoice", invoiceId),
        genReqData(inputData, true)
    );

    return data;
};

export const refundBusinessBuilder = async (businessBuilderId, inputData) => {
    const { data } = await axiosInstance.post(
        buildPath("/api/admin/refund-builder-subscription", businessBuilderId),
        genReqData(inputData)
    );

    return data;
};

export default refundUser;
